import { CurrentUserPartsFragment } from 'gql'

export const currentUserMock: CurrentUserPartsFragment = {
  __typename: 'User',
  id: '13608',
  hasApplied: false,
  hasMemberAccess: true,
  preferredCourseView: 'new',
  hasUseableCohortCredits: false,
  numberOfUseableCohortCredits: 0,
  hasContentInProgressOrComplete: false,
  accessPolicyKind: 'member',
  shouldSeeTrialMessaging: false,
  shouldSeeHomepageCoursesSection: true,
  onboarded: true,
  subscriptionJoinRequests: [
    {
      id: '1',
      __typename: 'SubscriptionJoinRequest',
      status: 'pending',
      subscription: { id: '1', __typename: 'Subscription', name: 'Adobe' }
    }
  ],
  allowDirectMessages: false,
  networkOnboarded: true,
  networkRecommendations: [],
  networkFocus: [],
  userCourses: {
    __typename: 'UserCourses'
  },
  profile: {
    __typename: 'UserProfile',
    avatarUrl: 'reforge.com/mockAvatar',
    avatarXSmallUrl: 'reforge.com/mockAvatar',
    firstName: 'Fake User',
    hasBadge: false
  },
  contact: {
    __typename: 'UserContact',
    allEmails: [],
    primaryEmail: 'userEmail@mock.com'
  },
  is: {
    __typename: 'UserIs',
    accepted: true,
    admin: false,
    accountCreated: false,
    enrolledInLiveProgram: false,
    enrolledInEnrollmentSeason: false,
    enrolledInActiveCourse: false,
    expired: false,
    freeUser: false,
    member: true,
    paidMember: true,
    premember: false,
    staff: false,
    creator: false,
    collective: false,
    subscriptionOwner: true,
    teamSubscriptionOwner: true,
    assistantAdmin: false,
    planManager: false,
    noAccess: false,
    copyHold: false,
    banned: false,
    provisionedByScim: false,
    trialing: false,
    eligibleForTrial: false,
    individualMember: true
  },
  can: {
    __typename: 'UserCan',
    accessAllCohortEventReplays: false,
    pay: true,
    enrollInCohort: true,
    onboard: true,
    linkToSearchResults: true,
    viewActivity: true,
    viewActivitySidebar: true,
    viewCollectionsIndex: true,
    viewConceptsIndex: true,
    viewConceptsProjectsSidebar: null,
    viewDiscussionIndex: true,
    viewEvent: true,
    viewEventsIndex: true,
    viewLearningGoals: true,
    viewMemberIndex: true,
    viewMemberProfile: true,
    viewPost: true,
    viewPrograms: true,
    viewProjectsIndex: true,
    viewSearchTabs: true,
    viewSidebarGroups: true,
    viewProjectsAndConcepts: true,
    viewProgramsDashboard: true,
    viewSubscriptionSummary: true,
    viewBilling: true,
    viewCohortHistory: true,
    viewCohortIndex: true,
    manageMyTeam: false,
    assistantManageTeam: false,
    viewManageSeats: false,
    viewOrg: false
  },
  eventSpeakerCohortsForActiveSeasons: [],
  hostCohortsForActiveSeasons: [],
  cohorts: {
    __typename: 'UserCohorts',
    current: [],
    latestCurrent: null,
    previous: [],
    all: []
  },
  hasSeenCollectionsPrompt: true
}

export const currentUserPlanManagerMock: CurrentUserPartsFragment = {
  __typename: 'User',
  id: '13608',
  hasApplied: false,
  hasMemberAccess: true,
  hasUseableCohortCredits: false,
  numberOfUseableCohortCredits: 0,
  preferredCourseView: 'new',
  accessPolicyKind: 'plan_manager',
  shouldSeeTrialMessaging: false,
  shouldSeeHomepageCoursesSection: true,
  onboarded: true,
  subscriptionJoinRequests: [],
  allowDirectMessages: false,
  hasContentInProgressOrComplete: false,
  networkOnboarded: true,
  networkRecommendations: [],
  userCourses: {
    __typename: 'UserCourses'
  },
  profile: {
    __typename: 'UserProfile',
    avatarUrl: 'reforge.com/mockAvatar',
    avatarXSmallUrl: 'reforge.com/mockAvatar',
    firstName: 'Fake User',
    hasBadge: false
  },
  contact: {
    __typename: 'UserContact',
    allEmails: [],
    primaryEmail: 'userEmail@mock.com'
  },
  is: {
    __typename: 'UserIs',
    individualMember: true,
    subscriptionOwner: true,
    accepted: false,
    admin: false,
    assistantAdmin: false,
    accountCreated: false,
    collective: false,
    creator: false,
    enrolledInLiveProgram: false,
    enrolledInEnrollmentSeason: false,
    enrolledInActiveCourse: false,
    expired: false,
    freeUser: false,
    member: false,
    paidMember: false,
    staff: false,
    premember: true,
    teamSubscriptionOwner: false,
    planManager: false,
    noAccess: false,
    copyHold: false,
    banned: false,
    provisionedByScim: false,
    trialing: false,
    eligibleForTrial: false
  },
  can: {
    __typename: 'UserCan',
    accessAllCohortEventReplays: false,
    pay: true,
    onboard: false,
    enrollInCohort: false,
    linkToSearchResults: false,
    manageMyTeam: true,
    assistantManageTeam: false,
    viewActivity: false,
    viewActivitySidebar: false,
    viewCollectionsIndex: false,
    viewCohortIndex: false,
    viewConceptsIndex: true,
    viewConceptsProjectsSidebar: null,
    viewDiscussionIndex: false,
    viewEvent: false,
    viewEventsIndex: false,
    viewLearningGoals: null,
    viewMemberIndex: false,
    viewMemberProfile: false,
    viewPost: false,
    viewPrograms: true,
    viewProjectsIndex: true,
    viewSearchTabs: false,
    viewSidebarGroups: false,
    viewProjectsAndConcepts: true,
    viewProgramsDashboard: true,
    viewSubscriptionSummary: false,
    viewBilling: true,
    viewCohortHistory: false,
    viewManageSeats: true,
    viewOrg: false
  },
  eventSpeakerCohortsForActiveSeasons: [],
  hostCohortsForActiveSeasons: [],
  cohorts: {
    __typename: 'UserCohorts',
    current: [],
    latestCurrent: null,
    previous: [],
    all: []
  },
  hasSeenCollectionsPrompt: true
}

export const currentUserAcceptedMock: CurrentUserPartsFragment = {
  __typename: 'User',
  id: '13608',
  hasApplied: false,
  preferredCourseView: 'new',
  hasMemberAccess: false,
  accessPolicyKind: 'accepted',
  shouldSeeTrialMessaging: false,
  shouldSeeHomepageCoursesSection: true,
  hasContentInProgressOrComplete: false,
  subscriptionJoinRequests: [],
  onboarded: true,
  hasUseableCohortCredits: false,
  numberOfUseableCohortCredits: 0,
  allowDirectMessages: false,
  networkOnboarded: true,
  networkRecommendations: [],
  networkFocus: [],
  userCourses: {
    __typename: 'UserCourses'
  },
  profile: {
    __typename: 'UserProfile',
    avatarUrl: 'reforge.com/mockAvatar',
    avatarXSmallUrl: 'reforge.com/mockAvatar',
    firstName: 'Fake User',
    hasBadge: false
  },
  contact: {
    __typename: 'UserContact',
    allEmails: [],
    primaryEmail: 'userEmail@mock.com'
  },
  is: {
    __typename: 'UserIs',
    individualMember: true,
    accepted: true,
    admin: false,
    accountCreated: false,
    enrolledInLiveProgram: false,
    enrolledInEnrollmentSeason: false,
    enrolledInActiveCourse: false,
    expired: false,
    freeUser: true,
    member: false,
    paidMember: false,
    premember: true,
    staff: false,
    collective: false,
    creator: false,
    subscriptionOwner: false,
    teamSubscriptionOwner: false,
    assistantAdmin: false,
    planManager: false,
    noAccess: false,
    copyHold: false,
    banned: false,
    provisionedByScim: false,
    trialing: false,
    eligibleForTrial: false
  },
  can: {
    __typename: 'UserCan',
    accessAllCohortEventReplays: false,
    pay: true,
    enrollInCohort: true,
    onboard: true,
    linkToSearchResults: true,
    viewActivity: false,
    viewActivitySidebar: false,
    viewCollectionsIndex: false,
    viewConceptsIndex: false,
    viewConceptsProjectsSidebar: null,
    viewDiscussionIndex: false,
    viewEvent: false,
    viewEventsIndex: false,
    viewLearningGoals: false,
    viewMemberIndex: false,
    viewMemberProfile: false,
    viewPost: false,
    viewPrograms: false,
    viewProjectsIndex: false,
    viewSearchTabs: true,
    viewSidebarGroups: false,
    viewProjectsAndConcepts: false,
    viewProgramsDashboard: false,
    viewSubscriptionSummary: false,
    viewBilling: false,
    viewCohortHistory: false,
    viewCohortIndex: false,
    manageMyTeam: false,
    viewManageSeats: false,
    assistantManageTeam: false,
    viewOrg: false
  },
  eventSpeakerCohortsForActiveSeasons: [],
  hostCohortsForActiveSeasons: [],
  cohorts: {
    __typename: 'UserCohorts',
    current: [],
    latestCurrent: null,
    previous: [],
    all: []
  },
  hasSeenCollectionsPrompt: true
}
