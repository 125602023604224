import { CmsProgramListPartsFragment } from 'gql'

export const cmsProgramMock: CmsProgramListPartsFragment = {
  __typename: 'CmsProgram',
  id: '128',
  name: 'Cms Program',
  slug: 'the-cms-program',
  topic: 'The Cms Program',
  synopsis: 'The Cms Program',
  releaseSeasonDate: null,
  releaseStartDate: null,
  launchAt: null,
  releaseEnrollmentDate: null,
  recommendationRanking: null,
  contentBookmarkId: null,
  deprecatedAt: null,
  notLaunched: false,
  canEnroll: false,
  enrolled: false,
  canEnrollForCurrentSeason: false,
  hasPreviewableContent: false,
  lessonCount: 0,
  progressPercent: 0
}

export const futureCmsProgramMock: CmsProgramListPartsFragment = {
  ...cmsProgramMock,
  name: 'Fake Future Cms Program',
  synopsis: 'Will we exist in the future? In the year 2100?',
  notLaunched: true,
  releaseSeasonDate: 'Aug 4, 2100',
  releaseStartDate: 'Aug 4, 2100'
}

export const enrollableCmsProgramMock: CmsProgramListPartsFragment = {
  ...cmsProgramMock,
  name: 'Enrollable Cms Program',
  synopsis: 'Live program enrollment is now open.',
  launchAt: '2100-10-04T00:00:00Z',
  canEnroll: true,
  canEnrollForCurrentSeason: true,
  hasPreviewableContent: true
}
