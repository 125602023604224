import React from 'react'
import { twMerge } from 'tailwind-merge'

import {
  CollapsibleContentBlock,
  typeForDisplay
} from 'pages/UnitLandingPage/UnitContentBlock'

import { UnitComplexPortableText } from 'domains/Sanity/PortableText/UnitComplexPortableText/UnitComplexPortableText'

import { Breadcrumb } from 'components/Breadcrumb'
import RfHeader1 from 'components/typography/RfHeader/RfHeader1'

import { CourseDetailCourseBlockPartsFragment } from 'gql'

import { ReactComponent as LightBulbRaysIcon } from 'images/icon--light-bulb-rays.svg'
import { ReactComponent as VideoIcon } from 'images/icon--video.svg'

interface CourseBlockContentBlockLandingPageProps {
  cclCourseBlock?: CourseDetailCourseBlockPartsFragment | null
  contentBlockSlug?: string
  backToCourseLabel: string
  backToCourseLink: string
}

const CourseBlockContentBlockLandingPage: React.FC<
  CourseBlockContentBlockLandingPageProps
> = ({ backToCourseLabel, backToCourseLink, cclCourseBlock, contentBlockSlug }) => {
  if (!cclCourseBlock || !contentBlockSlug || !cclCourseBlock.contentBlocks) {
    return null
  }

  const contentBlock = cclCourseBlock.contentBlocks?.find(
    (block) => block.slug === contentBlockSlug
  )

  if (!contentBlock) {
    return null
  }
  const renderCourseBlockContent = () => {
    return (
      <div className="space-y-12">
        {contentBlock.header && (
          <Breadcrumb
            id="guide-page-header"
            backTo={[{ label: backToCourseLabel, path: backToCourseLink }]}
            title={contentBlock.header}
          />
        )}

        <div className="rounded-xl bg-rb-orange-25 p-10 scroll-mt-16 w-full max-w-[780px] flex-col lg:w-3/4">
          {contentBlock.header && (
            <RfHeader1
              className={twMerge(
                'py-1 !font-polysans !font-light rf-h2',
                cclCourseBlock.hasVideoContent ? '!mb-5 md:!mb-8' : '!mb-3'
              )}
            >
              <div className="flex items-center">
                <div className="inline-flex items-center justify-center p-2 md:p-3 bg-gray-200 rounded-lg mr-3 md:mr-4">
                  {contentBlock.hasVideoContent ? (
                    <VideoIcon className="inline-block w-[22px] md:w-[28px] h-[22px] md:h-[28px]" />
                  ) : (
                    <LightBulbRaysIcon className="inline-block w-[22px] md:w-[28px] h-[22px] md:h-[28px]" />
                  )}
                </div>
                <span className="font-light">{contentBlock.header}</span>
              </div>
            </RfHeader1>
          )}

          {contentBlock.description && (
            <UnitComplexPortableText
              content={JSON.parse(contentBlock.description)}
              forcePhotoLoad
            />
          )}
          {contentBlock.contentBlockCollapsible &&
            contentBlock.contentBlockCollapsible.map((collapsibleContentItem, i) => (
              <CollapsibleContentBlock
                key={i}
                type={typeForDisplay(collapsibleContentItem.type)}
                label={collapsibleContentItem.label}
                description={JSON.parse(collapsibleContentItem.description)}
              />
            ))}
        </div>
      </div>
    )
  }

  return <div>{renderCourseBlockContent()}</div>
}

export default CourseBlockContentBlockLandingPage
