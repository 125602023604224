import { object, string } from 'yup'

import { UserSubscriptionCancelModalQuery } from 'gql'

import { formatInTimezone } from 'utils/date'

import { currentUserMock } from 'sharedmocks'

import { CancelMotivationType } from './SubscriptionCancelModalContent'

export const MOTIVATIONS_FOR_TEAM = [
  'I don’t want to be automatically charged',
  'Reforge isn’t as valuable as I expected it to be for my team',
  'I can’t get reimbursed by my company',
  'My team is too busy to take a live course in the near term',
  'None of the plan options fit my team’s needs'
]

export const MOTIVATIONS_FOR_INDIVIDUAL = [
  'I don’t want to be automatically charged',
  'Reforge isn’t as valuable as I expected it to be',
  'I can’t get reimbursed by my company',
  'I can no longer afford to pay for Reforge personally',
  'I’m too busy to take a live course in the near term',
  'I’m moving to a team plan'
]

export const validationSchema = object().shape({
  'motivationForCanceling': string().required(),
  'motivationForCanceling-Other-text': string()
})

export const getCancelationInfo = (
  isTeamPlan: boolean,
  data?: UserSubscriptionCancelModalQuery,
  timezone?: string | null
) => {
  const isDunning = !!data?.user?.subscriptions?.active?.isDunning
  const expiresAtDate = data?.user?.subscriptions?.active?.expiresAt || ''
  return isDunning
    ? `If you cancel, ${
        isTeamPlan ? 'your team' : 'you'
      } will lose Reforge access immediately. Keep your subscription active for continued access to your membership benefits.`
    : `If you cancel, ${isTeamPlan ? 'your team' : 'you'} will lose Reforge access on
                  ${formatInTimezone(
                    expiresAtDate,
                    timezone,
                    'MMMM do, yyyy'
                  )}. Keep your subscription active for continued access to your membership benefits.`
}

export const getWarningBannerCopy = (
  numberOfSeatsAffected: number,
  isMobile: boolean
) => {
  if (numberOfSeatsAffected > 0) {
    if (numberOfSeatsAffected === 1) {
      return isMobile
        ? 'You’re enrolled in an upcoming live program. You’ll lose access before the program ends.'
        : 'You’re enrolled in an upcoming Live Program. If you cancel, your subscription will expire and you’ll lose access to Reforge before this program ends.'
    } else {
      return isMobile
        ? 'Your team is enrolled in a live program. Their access will expire before the program ends.'
        : `${numberOfSeatsAffected} people on your team are enrolled in an upcoming Live Program. If you cancel, your subscription will expire and they will lose access to Reforge before the programs end.`
    }
  }
  return null
}

export const getCancelMotivationAsString = (
  cancelMotivation: CancelMotivationType | null
) => {
  let motivationForCanceling = cancelMotivation?.motivationForCanceling || ''

  if (
    cancelMotivation &&
    cancelMotivation.motivationForCanceling === 'Other' &&
    cancelMotivation.motivationOtherValue
  ) {
    motivationForCanceling = `Other: ${cancelMotivation.motivationOtherValue}`
  }

  return motivationForCanceling
}

// TODO: Use a centralized mock current user
export const mockCurrentUserData = {
  ...currentUserMock,
  id: '160286',
  timezone: 'Europe/Bucharest'
}
