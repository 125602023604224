import React from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

import AddBookmarkToFolderModal from 'domains/Bookmarks/AddBookmarkToFolderModal'
import CreateBookmarkFolderModal from 'domains/Collections/CreateBookmarkFolderModal'
import useOpenAddToBookmarkFolderModal from 'domains/Collections/hooks/useOpenAddToBookmarkFolderModal'
import useOpenCreateBookmarkFolderModal from 'domains/Collections/hooks/useOpenCreateBookmarkFolderModal'

import { Loading } from 'components'
import { ReactComponent as IconChevronRight } from 'components/Breadcrumbs/BaseBreadcrumbs/base-chevron-right.svg'

import {
  useBookmarkFoldersQuery,
  useExploreSubtopicPageQuery,
  useSavedArtifactsAndGuidesForUserQuery,
  useSavedCoursesForUserQuery
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { trackNavigationClicked } from 'utils/tracking/analytics'

import MoreTopicsToExplore from '../components/MoreTopicsToExplore'
import SubtopicSwimlane from '../components/SubtopicSwimlane'

const PAGE_LOCATION = 'subtopics index'

function BreadcrumbLink({
  to,
  onClick,
  children
}: {
  to: string
  onClick: () => void
  children: React.ReactNode
}) {
  return (
    <Link to={to} className="text-black hover:text-black underline" onClick={onClick}>
      {children}
    </Link>
  )
}

function Breadcrumbs({
  topic,
  subtopic
}: {
  topic: { title?: string | undefined; slug?: string | undefined; onClick: () => void }
  subtopic: { title: string; slug: string } | undefined
}) {
  return (
    <div className="flex gap-2">
      <BreadcrumbLink
        to="/explore"
        onClick={() => {
          trackNavigationClicked({
            type: 'hyperlink text',
            text: 'back to explore',
            location: location.pathname,
            destination: '/explore'
          })
        }}
      >
        Explore
      </BreadcrumbLink>
      <IconChevronRight fill={'#080A0A'} width={14.54} height={16} />
      <BreadcrumbLink to={`/explore/${topic?.slug}`} onClick={topic?.onClick}>
        {topic?.title}
      </BreadcrumbLink>
      <IconChevronRight fill={'#080A0A'} width={14.54} height={16} />
      {subtopic?.title}
    </div>
  )
}

export default function ExploreSubtopicPage() {
  const location = useLocation()
  const { currentUser } = useCurrentUser()
  const { topic, subtopic } = useParams<{ topic: string; subtopic: string }>()

  const { data, loading } = useExploreSubtopicPageQuery({
    variables: {
      subtopicSlug: subtopic,
      slugs: [topic, subtopic]
    }
  })

  const { artifacts, courses, guides } = data?.exploreSubtopicContent || {}

  const numRows = [artifacts?.length, courses?.length, guides?.length].filter(
    Boolean
  ).length

  const { data: savedData } = useSavedArtifactsAndGuidesForUserQuery({
    variables: {
      userId: currentUser?.id || ''
    }
  })

  const { data: savedCourseData } = useSavedCoursesForUserQuery({
    variables: {
      userId: currentUser?.id || ''
    }
  })

  const {
    currentBookmarkForDropdown,
    isAddToBookmarkFolderModalOpen,
    closeAddToBookmarkFolderModal,
    openAddToBookmarkFolderModal
  } = useOpenAddToBookmarkFolderModal()

  const {
    currentBookmarkForDropdown: currentBookmarkForDropdownForCreate,
    isCreateBookmarkFolderModalOpen,
    closeCreateBookmarkFolderModal,
    openCreateBookmarkFolderModal
  } = useOpenCreateBookmarkFolderModal()

  const handleOpenCreateBookmarkFolderModal = () => {
    closeAddToBookmarkFolderModal()
    openCreateBookmarkFolderModal(currentBookmarkForDropdown)
  }

  const topicData = data?.exploreTopicsForSlugs?.find((t) => t.slug === topic)
  const subtopicData = data?.exploreTopicsForSlugs?.find((t) => t.slug === subtopic)

  const { data: bookmarkFolderData } = useBookmarkFoldersQuery({ skip: !currentUser })

  if (loading) {
    return <Loading />
  }

  const artifactsSwimlaneTitle = 'See real examples & templates'
  const guidesSwimlaneTitle = 'Follow our helpful step-by-step guides'
  const coursesSwimlaneTitle = 'Dive into courses on this topic'

  const swimlanes = [
    { contentType: 'artifact', title: artifactsSwimlaneTitle, data: artifacts },
    { contentType: 'guide', title: guidesSwimlaneTitle, data: guides },
    { contentType: 'course', title: coursesSwimlaneTitle, data: courses }
  ].filter(({ data }) => data?.length)

  return (
    <>
      <div>
        <div className="mb-8">
          <Breadcrumbs
            topic={{
              ...topicData,
              onClick: () => {
                trackNavigationClicked({
                  type: 'hyperlink text',
                  text: `back to ${topicData?.title}`.toLowerCase(),
                  location: location.pathname,
                  destination: `/explore/${topicData?.slug}`
                })
              }
            }}
            subtopic={subtopicData}
          />
        </div>

        <div className="flex flex-col gap-6 mb-8">
          {swimlanes.map(({ title, contentType, data }, idx) => {
            if (!data) return null

            return (
              <SubtopicSwimlane
                key={`${contentType}-${idx}`}
                idx={idx}
                loading={loading}
                subtopicId={subtopicData?.id}
                title={title}
                items={data}
                savedData={savedData}
                savedCourseData={savedCourseData}
                openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
                tracking={{
                  pageLocation: PAGE_LOCATION,
                  contentType,
                  locationId: subtopicData?.id,
                  relatedIdentifiers: {
                    locationName: subtopicData?.title
                  },
                  section: title,
                  sectionIndex: idx
                }}
              />
            )
          })}
        </div>
        <MoreTopicsToExplore
          tracking={{ locationType: PAGE_LOCATION, sectionIndex: numRows }}
        />
      </div>

      {!currentUser?.is?.freeUser && (
        <>
          <AddBookmarkToFolderModal
            isOpen={isAddToBookmarkFolderModalOpen}
            handleClose={closeAddToBookmarkFolderModal}
            bookmarkFolders={bookmarkFolderData?.currentUser?.bookmarkFolders}
            openCreateBookmarkFolderModal={handleOpenCreateBookmarkFolderModal}
            currentBookmarkForDropdown={currentBookmarkForDropdown}
            showCollectionsOnboardingInfo={
              !bookmarkFolderData?.currentUser?.hasSeenCollectionsPrompt
            }
          />
          <CreateBookmarkFolderModal
            isModalOpen={isCreateBookmarkFolderModalOpen}
            handleClose={closeCreateBookmarkFolderModal}
            currentBookmarkForDropdown={currentBookmarkForDropdownForCreate}
            trackingTriggerAction={'bookmark'}
          />
        </>
      )}
    </>
  )
}
