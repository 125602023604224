import { ComponentType, useEffect, useState } from 'react'

import AnnouncementBannerCarousel, {
  AnnouncementBannerProps
} from 'components/dopt/AnnouncementBanner/AnnouncementBannerCarousel'

import {
  CompletedProductToursDocument,
  ProductTourKey,
  useCompleteProductTourMutation,
  useCompletedProductToursQuery
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { CourseLiveBanner } from './Banners/CourseLiveBanner'
import { RecommendationsBanner } from './Banners/RecommendationsBanner'
import { SlackBanner } from './Banners/SlackBanner'

export const availableBannersMap: Partial<
  Record<ProductTourKey | string, ComponentType<AnnouncementBannerProps>>
> = {
  [ProductTourKey.RECOMMENDATIONS]: RecommendationsBanner,
  'course-live-banner': CourseLiveBanner,
  'slack-announcement-banner': SlackBanner
}

type ActiveBannerKeys = 'recommendations'[]
const CURRENTLY_ACTIVE_COMPONENTS = ['recommendations'] as ActiveBannerKeys

const AnnouncementWrapper = () => {
  const { currentUser } = useCurrentUser()
  const { data, loading } = useCompletedProductToursQuery()
  const [completeProductTour] = useCompleteProductTourMutation()
  const [activeComponents, setActiveComponents] = useState<string[]>([])

  const onClick = (identifier: ProductTourKey | string) => {
    if (!data?.currentUser?.completedProductTours) return null

    completeProductTour({
      variables: {
        input: {
          productTourKey: identifier as ProductTourKey
        }
      },
      refetchQueries: [CompletedProductToursDocument]
    })
  }

  useEffect(() => {
    const temp = []
    const showLiveCourseBanner =
      localStorage.getItem('dismiss-live-course-banner') !== '1'

    if (showLiveCourseBanner) {
      temp.push('course-live-banner')
    }

    if (currentUser?.is?.member) {
      CURRENTLY_ACTIVE_COMPONENTS.forEach((key) => {
        if (!data?.currentUser?.completedProductTours?.[key]) {
          temp.push(key)
        }
      })

      if (
        !currentUser?.is?.trialing &&
        !data?.currentUser?.hideSlackBanner &&
        !currentUser?.slackUserId
      ) {
        temp.push('slack-announcement-banner')
      }
    }

    setActiveComponents(temp)
  }, [data, loading, currentUser])

  if (loading || !data?.currentUser) return null

  return (
    <AnnouncementBannerCarousel
      setActiveComponents={setActiveComponents}
      activeComponents={activeComponents}
      completeProductTour={onClick}
    />
  )
}

export default AnnouncementWrapper
