import { format } from 'date-fns'
import { PopoverPosition } from 'react-tiny-popover'

import Image from 'domains/Sanity/Image'
import { guideThumbnailSrc } from 'domains/Unit/helpers'

import { FacePile, FacePileUser } from 'components/FacePile'
import PremiumPill from 'components/PremiumPill'

import { MAX_WIDTH_TAILWIND_SM } from 'constants/breakpoints'

import {
  ArtifactAuthor,
  BookmarkFolderPartsFragment,
  GuideBookmarkPartsFragment,
  ProgramBookmarkPartsFragment,
  Unit
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import useMediaQuery from 'hooks/useMediaQuery'

import { FULL_DATE_ABBREV_MONTH_FORMAT } from 'utils/date'
import { listify } from 'utils/stringUtils'
import { cn } from 'utils/tailwind'
import { trackContentClicked } from 'utils/tracking/analytics'

import BaseCard, { CardVariant, CardVariants } from './BaseCard'

export interface GuideCardProps {
  guide: any
  variant?: CardVariant
  swimlaneSlug?: string
  pageLocation?: string
  additionalRelatedIdentifiers?: {}
  // Bookmark-related props
  bookmark?: GuideBookmarkPartsFragment
  currentFolder?: BookmarkFolderPartsFragment | null
  bookmarkFolders?: BookmarkFolderPartsFragment[] | undefined
  openAddToBookmarkFolderModal?: (bookmark: ProgramBookmarkPartsFragment) => void
  restoreBookmark?: (bookmark: ProgramBookmarkPartsFragment) => void
  handleRemoveFromFolder?: (
    bookmarkId: string,
    bookmarkFolder: BookmarkFolderPartsFragment
  ) => Promise<string | null | undefined>
  hideBookmarkButton?: boolean
  // This is needed because some cards are rendered in a container that manages overflow (e.g., uk-slider) that
  // will clip the bookmark dropdown menu now that the button is at the bottom of the card.
  bookmarkDropdownPosition?: PopoverPosition
  showPublishDateInFooter?: boolean
  customHoverMiniCard?: boolean
  overrideImage?: string | null
  impressionTrackingProperties?: { [key: string]: any }
  sectionIndex?: number
  sectionImpressionIndex?: number
}

const GuideCard = ({
  guide,
  variant = CardVariants.Vertical,
  pageLocation = 'guides_index',
  swimlaneSlug,
  bookmark,
  currentFolder,
  bookmarkFolders,
  openAddToBookmarkFolderModal,
  restoreBookmark,
  handleRemoveFromFolder,
  additionalRelatedIdentifiers,
  hideBookmarkButton,
  bookmarkDropdownPosition,
  showPublishDateInFooter,
  customHoverMiniCard,
  overrideImage,
  impressionTrackingProperties,
  sectionIndex,
  sectionImpressionIndex
}: GuideCardProps) => {
  const { currentUser } = useCurrentUser()
  const pathParam = swimlaneSlug ? `?swimlane=${swimlaneSlug}` : ''
  const destination = `/guides/${guide?.slug}${pathParam}`
  const hideBookmark = hideBookmarkButton || !currentUser

  const relatedIdentifiers = {
    referrer: window.location.pathname,
    content_mode: 'async',
    ...additionalRelatedIdentifiers
  }

  const handleTracking = () => {
    trackContentClicked({
      content_sanity_id: guide.id,
      content_title: guide.title,
      content_type: 'Guide',
      path: destination,
      location: pageLocation,
      display_type: `${variant}_card`,
      logged_in: !!currentUser,
      is_previewable: !!guide?.previewable,
      related_identifiers: relatedIdentifiers,
      section_index: sectionIndex,
      section_impression_index: sectionImpressionIndex
    })
  }

  const showPremiumIcon =
    !guide?.previewable && (!currentUser || !!currentUser?.is?.premember)

  return (
    <BaseCard
      contentType="Guide"
      variant={variant}
      sanityId={guide.sanityId || guide.id}
      title={guide.title}
      header={
        <GuideHeader guide={guide} showPremiumIcon={showPremiumIcon} variant={variant} />
      }
      body={guide.shortDescription || guide.description}
      thumbnail={<Thumbnail guide={guide} overrideSrc={overrideImage} />}
      horizontalThumbnail={
        <GuideHorizontalThumbnail guide={guide} overrideSrc={overrideImage} />
      }
      verticalThumbnail={
        <GuideVerticalThumbnail
          guide={guide}
          overrideSrc={overrideImage}
          showPremiumIcon={showPremiumIcon}
        />
      }
      footer={
        <GuideFooter showPublishDate={showPublishDateInFooter || false} guide={guide} />
      }
      destination={destination}
      impressionTrackingProperties={impressionTrackingProperties}
      trackCardClick={handleTracking}
      bookmarkId={guide.contentBookmarkId}
      bookmark={bookmark}
      currentFolder={currentFolder}
      bookmarkFolders={bookmarkFolders}
      openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
      restoreBookmark={restoreBookmark}
      handleRemoveFromFolder={handleRemoveFromFolder}
      hideBookmarkButton={hideBookmark}
      bookmarkDropdownPosition={bookmarkDropdownPosition}
      customHoverMiniCard={customHoverMiniCard}
      showPremiumIcon={showPremiumIcon}
    />
  )
}

const GuideHeader = ({
  guide,
  showPremiumIcon,
  variant
}: {
  guide: Unit
  showPremiumIcon: boolean
  variant: CardVariant
}) => {
  const isSmall = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_SM})`)
  if (!guide.authors) return null
  const authors = guide.authors as ArtifactAuthor[]

  const authorNames = authors
    .filter((author) => author && author.name)
    .map((author) => author.name) as string[]
  const facepileUsers = authors
    .filter((author) => author && (author.avatarPhoto?.imageUrl || author.avatarUrl))
    .map((author) => {
      return {
        id: author.id,
        avatarUrl: author.avatarPhoto?.imageUrl || author.avatarUrl
      } as FacePileUser
    })

  return (
    <div className="flex gap-2 relative">
      <div className="flex shrink-0 grow-0">
        <FacePile users={facepileUsers} imageSize="small" />
      </div>
      <div
        className={cn(
          'text-ellipsis line-clamp-1',
          showPremiumIcon && variant === CardVariants.Horizontal && 'pr-14'
        )}
      >
        {listify(authorNames)}
      </div>

      {showPremiumIcon && variant === CardVariants.Horizontal && !isSmall && (
        <div className="absolute -top-0.5 right-0">
          <PremiumPill />
        </div>
      )}
    </div>
  )
}

const GuideVerticalThumbnail = ({
  guide,
  overrideSrc
}: {
  guide: Unit
  showPremiumIcon: boolean
  overrideSrc?: string | null
}) => {
  const imgSrc = overrideSrc || guideThumbnailSrc(guide)

  return (
    <Image
      className="h-full w-full rounded-t-xl object-cover"
      src={imgSrc}
      alt={guide.title || ''}
    />
  )
}

const GuideHorizontalThumbnail = ({
  guide,
  overrideSrc
}: {
  guide: Unit
  overrideSrc?: string | null
}) => {
  const imgSrc = overrideSrc || guideThumbnailSrc(guide)
  return (
    <>
      <Image
        className="h-full w-full rounded-xl object-cover"
        src={imgSrc}
        alt={guide.title || ''}
      />
    </>
  )
}

const Thumbnail = ({
  guide,
  overrideSrc
}: {
  guide: Unit
  overrideSrc?: string | null
}) => {
  const imgSrc = overrideSrc || guideThumbnailSrc(guide)
  return (
    <Image
      className="h-full w-full rounded-xl object-cover"
      src={imgSrc}
      alt={guide.title || ''}
    />
  )
}

const GuideFooter = ({
  guide,
  showPublishDate
}: {
  guide: Unit
  showPublishDate: boolean
}) => {
  const steps = guide.stepsCount || 0

  return (
    <div className="whitespace-nowrap">
      {showPublishDate && guide.publishDate && (
        <>
          <span>
            Published {format(new Date(guide.publishDate), FULL_DATE_ABBREV_MONTH_FORMAT)}
          </span>
          <span className="px-1">·</span>
        </>
      )}
      <span>{steps} Steps</span>
      <span className="px-1">·</span>
      <span>{guide.estimatedTimeInMinutes} min read</span>
    </div>
  )
}

export default GuideCard
