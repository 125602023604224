import React, { useMemo, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

import { CTA_LINK_STYLES } from 'pages/GuidesPage/SwimlaneIndex'

import AddBookmarkToFolderModal from 'domains/Bookmarks/AddBookmarkToFolderModal'
import CreateBookmarkFolderModal from 'domains/Collections/CreateBookmarkFolderModal'
import useOpenAddToBookmarkFolderModal from 'domains/Collections/hooks/useOpenAddToBookmarkFolderModal'
import useOpenCreateBookmarkFolderModal from 'domains/Collections/hooks/useOpenCreateBookmarkFolderModal'

import { Loading } from 'components'
import { ReactComponent as IconChevronRight } from 'components/Breadcrumbs/BaseBreadcrumbs/base-chevron-right.svg'
import PageHeader from 'components/PageHeader'
import ChevronRightThinIcon from 'components/icons/ChevronRightThinIcon'
import RfCardTitle from 'components/typography/RfCard/RfCardTitle'

import {
  useBookmarkFoldersQuery,
  useExploreSubtopicContentQuery,
  useExploreTopicPageQuery,
  useSavedArtifactsAndGuidesForUserQuery,
  useSavedCoursesForUserQuery
} from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { trackNavigationClicked } from 'utils/tracking/analytics'

import MoreTopicsToExplore from '../components/MoreTopicsToExplore'
import SubtopicSwimlane from '../components/SubtopicSwimlane'
import { TOPICS } from '../consts'

const PAGE_LOCATION = 'topic index'
const NUM_INIT_SUBTOPICS_TO_SHOW = 4

// TODO: Update the breadcrumb component to get rid of needing the top level "Reforge"
// and to allow more than just one-level breadcrumbs to pass in custom tracking specs.
// Then get rid of all this custom code.

function BreadcrumbLink({
  to,
  onClick,
  children
}: {
  to: string
  onClick: () => void
  children: React.ReactNode
}) {
  return (
    <Link to={to} className="text-black hover:text-black underline" onClick={onClick}>
      {children}
    </Link>
  )
}

function Breadcrumbs({ topic }: { topic: { title?: string | undefined } }) {
  return (
    <div className="flex gap-2">
      <BreadcrumbLink
        to="/explore"
        onClick={() => {
          trackNavigationClicked({
            type: 'hyperlink text',
            text: 'back to explore',
            location: location.pathname,
            destination: '/explore'
          })
        }}
      >
        Explore
      </BreadcrumbLink>
      <IconChevronRight fill={'#080A0A'} width={14.54} height={16} />
      {topic.title}
    </div>
  )
}

function TopicPageHeader({
  topicTitle,
  topicSlug,
  topicId,
  subtopics,
  topicImage,
  isLoggedIn,
  backgroundColor
}: {
  topicTitle: string
  topicSlug: string
  topicId: string
  subtopics: any[]
  topicImage: React.ReactNode
  isLoggedIn: boolean
  backgroundColor: string
}) {
  const [viewAll, setViewAll] = useState(false)

  const renderedSubtopics = viewAll
    ? subtopics
    : subtopics.slice(0, NUM_INIT_SUBTOPICS_TO_SHOW)

  return (
    <div>
      <div
        className="flex p-8 justify-between items-center rounded-lg"
        style={{ backgroundColor: backgroundColor }}
      >
        <div>
          <PageHeader title={topicTitle} customClassName="mb-0 md:mb-6" />
          <div className="flex">
            <div className="flex flex-row gap-2 flex-wrap hidden md:flex">
              {renderedSubtopics.map((subtopic, idx) => {
                const subtopicPath = `/explore/${topicSlug}/${subtopic.slug}`

                return (
                  <div key={`subtopic-${idx}`}>
                    <Link
                      to={subtopicPath}
                      className="flex inline-flex items-center border bg-rb-white border-rb-gray-400 px-2 py-1 justify-center items-center gap-1.5 rounded-md text-rb-gray-400 hover:text-rb-gray-400 hover:no-underline text-xs"
                      onClick={() =>
                        trackNavigationClicked({
                          type: 'clickable tag',
                          text: subtopic.title.toLowerCase(),
                          location: location.pathname,
                          location_type: PAGE_LOCATION,
                          location_id: topicId,
                          logged_in: isLoggedIn,
                          destination: subtopicPath,
                          related_identifiers: {
                            destination_ccl_filter_tag_id: subtopic.id,
                            destination_kind: 'subtopic'
                          },
                          section_index: 0,
                          section_impression_index: idx
                        })
                      }
                    >
                      {subtopic.title}
                    </Link>
                  </div>
                )
              })}
              {!viewAll && subtopics.length > NUM_INIT_SUBTOPICS_TO_SHOW && (
                <button
                  className="flex text-rb-gray-300 text-sm items-center"
                  onClick={() => setViewAll(true)}
                >
                  view all
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="flex shrink-0 h-auto">{topicImage}</div>
      </div>
    </div>
  )
}

function SubtopicSwimlaneContainer({
  pageTopicSlug,
  idx,
  subtopic,
  savedData,
  savedCourseData,
  openAddToBookmarkFolderModal,
  tracking,
  topicId,
  isLoggedIn,
  sectionIndex
}: {
  pageTopicSlug: string
  idx: number
  subtopic: { title: string; slug: string; id: string }
  savedData: any
  savedCourseData: any
  openAddToBookmarkFolderModal: any
  tracking: any
  topicId: string
  isLoggedIn: boolean
  sectionIndex: number
}) {
  const location = useLocation()

  const { data, loading } = useExploreSubtopicContentQuery({
    variables: {
      subtopicSlug: subtopic.slug
    }
  })

  const items = useMemo(
    () => [
      ...(data?.exploreSubtopicContent?.artifacts || []),
      ...(data?.exploreSubtopicContent?.guides || []),
      ...(data?.exploreSubtopicContent?.courses || [])
    ],
    [
      data?.exploreSubtopicContent?.artifacts,
      data?.exploreSubtopicContent?.courses,
      data?.exploreSubtopicContent?.guides
    ]
  )

  const subtopicPath = `/explore/${pageTopicSlug}/${subtopic.slug}`

  return (
    <SubtopicSwimlane
      idx={idx}
      loading={loading}
      subtopicId={subtopic.id}
      title={
        <div className="flex">
          <RfCardTitle>{subtopic.title}</RfCardTitle>
          <Link
            to={subtopicPath}
            className={CTA_LINK_STYLES}
            onClick={() =>
              trackNavigationClicked({
                type: 'hyperlink text',
                text: subtopic.title.toLowerCase(),
                location: location.pathname,
                location_type: PAGE_LOCATION,
                location_id: topicId,
                logged_in: isLoggedIn,
                destination: subtopicPath,
                related_identifiers: {
                  destination_ccl_filter_tag_id: subtopic.id,
                  destination_kind: 'subtopic'
                },
                section_index: sectionIndex
                // intentionally not sending section_impression_index for "view all"
              })
            }
          >
            view all
            <ChevronRightThinIcon className="h-3 fill-current pl-2" />
          </Link>
        </div>
      }
      items={items}
      savedData={savedData}
      savedCourseData={savedCourseData}
      openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
      tracking={tracking}
    />
  )
}

export default function TopicPage() {
  const { topic } = useParams<{ topic: string }>()
  const { currentUser, isLoggedIn } = useCurrentUser()

  const { data, loading } = useExploreTopicPageQuery({
    variables: {
      slug: topic
    }
  })

  const { data: savedData } = useSavedArtifactsAndGuidesForUserQuery({
    variables: {
      userId: currentUser?.id || ''
    }
  })

  const { data: savedCourseData } = useSavedCoursesForUserQuery({
    variables: {
      userId: currentUser?.id || ''
    }
  })

  const {
    currentBookmarkForDropdown,
    isAddToBookmarkFolderModalOpen,
    closeAddToBookmarkFolderModal,
    openAddToBookmarkFolderModal
  } = useOpenAddToBookmarkFolderModal()

  const {
    currentBookmarkForDropdown: currentBookmarkForDropdownForCreate,
    isCreateBookmarkFolderModalOpen,
    closeCreateBookmarkFolderModal,
    openCreateBookmarkFolderModal
  } = useOpenCreateBookmarkFolderModal()

  const handleOpenCreateBookmarkFolderModal = () => {
    closeAddToBookmarkFolderModal()
    openCreateBookmarkFolderModal(currentBookmarkForDropdown)
  }
  const { data: bookmarkFolderData } = useBookmarkFoldersQuery({ skip: !currentUser })

  const pageTopicSlug = topic
  const pageTopicTitle = data?.exploreSubtopicsOfTopic?.title || ''
  const pageTopicId = data?.exploreSubtopicsOfTopic?.id || ''
  const subtopics = data?.exploreSubtopicsOfTopic?.subtopics || []
  const topicConstantData = TOPICS.find((t: any) => t.slug === pageTopicSlug)
  const topicImage = topicConstantData?.icon
  const backgroundColor = topicConstantData?.backgroundColor || '#FCFBFA'

  if (loading) {
    return <Loading />
  }

  return (
    <>
      <div className="flex flex-col gap-6">
        <div>
          <div className="mb-8">
            <Breadcrumbs
              topic={{
                title: pageTopicTitle
              }}
            />
          </div>

          <TopicPageHeader
            isLoggedIn={isLoggedIn}
            subtopics={subtopics}
            topicId={pageTopicId}
            topicImage={topicImage}
            topicTitle={pageTopicTitle}
            topicSlug={pageTopicSlug}
            backgroundColor={backgroundColor}
          />

          <div className="pt-12 flex flex-col gap-4">
            {subtopics.map((subtopic, idx) => {
              return (
                <SubtopicSwimlaneContainer
                  key={`subtopic-${idx}`}
                  idx={idx}
                  isLoggedIn={isLoggedIn}
                  topicId={pageTopicId}
                  pageTopicSlug={pageTopicSlug}
                  subtopic={subtopic}
                  savedData={savedData}
                  savedCourseData={savedCourseData}
                  sectionIndex={idx}
                  openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
                  tracking={{
                    contentType: 'subtopic',
                    locationId: pageTopicId,
                    pageLocation: PAGE_LOCATION,
                    relatedIdentifiers: {
                      locationName: pageTopicTitle
                    }
                  }}
                />
              )
            })}
          </div>
        </div>
        <MoreTopicsToExplore
          tracking={{ locationType: PAGE_LOCATION, sectionIndex: subtopics?.length + 1 }}
        />
      </div>
      {!currentUser?.is?.freeUser && (
        <>
          <AddBookmarkToFolderModal
            isOpen={isAddToBookmarkFolderModalOpen}
            handleClose={closeAddToBookmarkFolderModal}
            bookmarkFolders={bookmarkFolderData?.currentUser?.bookmarkFolders}
            openCreateBookmarkFolderModal={handleOpenCreateBookmarkFolderModal}
            currentBookmarkForDropdown={currentBookmarkForDropdown}
            showCollectionsOnboardingInfo={
              !bookmarkFolderData?.currentUser?.hasSeenCollectionsPrompt
            }
          />
          <CreateBookmarkFolderModal
            isModalOpen={isCreateBookmarkFolderModalOpen}
            handleClose={closeCreateBookmarkFolderModal}
            currentBookmarkForDropdown={currentBookmarkForDropdownForCreate}
            trackingTriggerAction={'bookmark'}
          />
        </>
      )}
    </>
  )
}
