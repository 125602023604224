import { useEffect, useState } from 'react'

import { Loading } from 'components'

import { useUserCoachingSessionsQuery } from 'gql'

import { useQueryParams } from 'hooks/useQueryParams'

import { SuggestedGoals } from './components/SuggestedGoal'
import { Prompt } from './components/prompt/Prompt'
import { Recommendations } from './components/recommendations/Recommendations'
import { GoalSessionData } from './type'

const GoalsPage = () => {
  const { queryParams } = useQueryParams()
  const hasPreviewId = queryParams.has('previewId')
  const hasSessionId = queryParams.has('sessionId')
  const [activeSession, setActiveSession] = useState<GoalSessionData>()
  const [allSessions, setAllSessions] = useState<GoalSessionData[]>([])
  const [intialized, setInitialized] = useState(false)

  const { data, loading, refetch } = useUserCoachingSessionsQuery()

  useEffect(() => {
    if (data?.userCoachingSessions) {
      const sessiongId = queryParams.get('previewId') || queryParams.get('sessionId')
      const sessions: GoalSessionData[] = data.userCoachingSessions.map((session) => {
        return {
          id: session.id,
          externalId: session.externalId,
          data: { ...(session.data ?? {}) }
        }
      })

      const maybeActiveSession = sessions.find(
        (session) => String(session.externalId) === String(sessiongId)
      )

      if (maybeActiveSession) {
        setActiveSession(maybeActiveSession)
      }

      setAllSessions(sessions)
      setInitialized(true)
    }
  }, [data, queryParams])

  return (
    <div className="h-[calc(95vh-104px)] rounded-xl bg-[#EFF9F9] w-full flex justify-center overflow-auto">
      {loading && !intialized && <Loading />}
      {!loading && intialized && (
        <>
          {!hasPreviewId && !hasSessionId && (
            <Prompt args={{ setActiveSession, allSessions }} />
          )}
          {hasPreviewId && (
            <SuggestedGoals
              args={{ activeSession, allSessions, refetchSessions: refetch }}
            />
          )}
          {hasSessionId && (
            <Recommendations
              args={{
                setActiveSession,
                activeSession,
                allSessions,
                refetchSessions: refetch
              }}
            />
          )}
        </>
      )}
    </div>
  )
}

export default GoalsPage
