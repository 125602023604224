import { useState } from 'react'

import ArtifactCard from 'components/cards/Content/ArtifactCard'
import { CardVariants } from 'components/cards/Content/BaseCard'
import GuideCard from 'components/cards/Content/GuideCard'
import LegacyContentCard from 'components/cards/Content/LegacyContentCard'
import { SkeletonMiniCard } from 'components/skeletons/cards/SkeletonMiniCard'

import {
  HomepageRecentlyViewedItem,
  ProgramBookmarkPartsFragment,
  SavedArtifactsAndGuidesForUserQuery
} from 'gql'

import useContainerQuery from 'hooks/useContainerQuery'

import { onEnterKeyPress } from 'utils/keyboard'
import { trackCtaClicked } from 'utils/tracking/analytics'

interface RecentlyViewedContentProps {
  items: HomepageRecentlyViewedItem[]
  loading: boolean
  savedData?: SavedArtifactsAndGuidesForUserQuery
  openAddToBookmarkFolderModal?: (bookmark: ProgramBookmarkPartsFragment) => void
  pageLocation: string
}

const RecentlyViewedContent = ({
  items,
  loading,
  pageLocation,
  savedData,
  openAddToBookmarkFolderModal
}: RecentlyViewedContentProps) => {
  const [{ isSmallerView }, containerRef] = useContainerQuery({
    isSmallerView: {
      maxWidth: 932
    }
  })
  const [showingMore, setShowingMore] = useState(false)
  const buttonText = showingMore ? 'Show less' : 'Show more'
  const perPage = isSmallerView ? 2 : 3

  const handleShowMoreClick = () => {
    setShowingMore(!showingMore)
    trackCtaClicked({
      cta_location: pageLocation,
      cta_type: 'button',
      text: buttonText
    })
  }

  const viewableItems = showingMore ? items : items.slice(0, perPage)

  const renderItem = (item: HomepageRecentlyViewedItem, i: number) => {
    switch (item.__typename) {
      case 'Artifact':
        return (
          <ArtifactCard
            artifact={item}
            variant={CardVariants.Mini}
            customHoverMiniCard={true}
            showPublishDateInFooter
            pageLocation={pageLocation}
            additionalRelatedIdentifiers={{
              section: 'recently_viewed',
              result_index: i
            }}
            bookmark={savedData?.savedArtifactsAndGuidesForUser?.find(
              (bookmark) => bookmark.sanityId === item.id
            )}
            customFooter={
              !isSmallerView && (
                <div className="text-xs text-rb-gray-300 line-clamp-1">
                  {item.authors?.[0].name}
                </div>
              )
            }
            openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
            bookmarkDropdownPosition="left"
          />
        )
      case 'Unit':
        return (
          <GuideCard
            guide={item}
            variant={CardVariants.Mini}
            customHoverMiniCard={true}
            pageLocation={pageLocation}
            additionalRelatedIdentifiers={{
              section: 'recently_viewed',
              result_index: i
            }}
            bookmark={savedData?.savedArtifactsAndGuidesForUser?.find(
              (bookmark) => bookmark.sanityId === item.id
            )}
            openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
            bookmarkDropdownPosition="left"
          />
        )
      case 'Content':
        return (
          <LegacyContentCard
            content={{
              ...item.cmsSection,
              href: item.cmsSection.href,
              contentBookmarkId: item.contentBookmarkId,
              accessLevel: item.accessLevel
            }}
            variant={CardVariants.Mini}
            customHoverMiniCard={true}
            pageLocation={pageLocation}
            additionalRelatedIdentifiers={{
              is_home_feed_result: false,
              is_recently_viewed_result: true,
              is_trending_result: false,
              result_index: i
            }}
            openAddToBookmarkFolderModal={openAddToBookmarkFolderModal}
            bookmarkDropdownPosition="left"
            customFooter={
              <div className="text-xs text-rb-gray-300 whitespace-nowrap overflow-ellipsis overflow-hidden">
                {item.cmsSection.cmsProgram.name}
              </div>
            }
          />
        )
      default:
        return null
    }
  }

  if (viewableItems.length === 0 && !loading) return null

  return (
    <div ref={containerRef} className="flex flex-col gap-4">
      <div className="flex flex-row items-center w-full align text-xl font-semibold">
        <span>Recently viewed</span>
        {items.length > perPage && (
          <div
            role="link"
            tabIndex={0}
            onKeyUp={onEnterKeyPress(handleShowMoreClick)}
            className="h-10 hidden xs:flex px-4 items-center text-rb-gray-300 font-normal text-sm cursor-pointer"
            onClick={handleShowMoreClick}
          >
            {buttonText}
          </div>
        )}
      </div>

      {loading && (
        <div className="grid w-full grid-cols-[repeat(auto-fill,minmax(300px,1fr))] mb-4 sm:mb-0 gap-4">
          <SkeletonMiniCard />
          <SkeletonMiniCard />
          {!isSmallerView && <SkeletonMiniCard />}
        </div>
      )}

      <div className="grid w-full grid-cols-[repeat(auto-fill,minmax(300px,1fr))] mb-4 sm:mb-0 gap-4">
        {!loading &&
          viewableItems.map((item, i) => (
            <div key={`recentlyViewed-${i}`}>{renderItem(item, i)}</div>
          ))}
      </div>
    </div>
  )
}

export default RecentlyViewedContent
