import { useHistory } from 'react-router-dom'

import renderSortable from 'domains/Shared/RenderSortable'

import { CardVariants } from 'components/cards/Content/BaseCard'
import ChevronRightThinIcon from 'components/icons/ChevronRightThinIcon'
import { SkeletonMiniCard } from 'components/skeletons/cards/SkeletonMiniCard'

import { COURSES_MY_COURSES_PATH } from 'constants/courses'

import { useMyCoursesQuery } from 'gql'

import useMediaQuery from 'hooks/useMediaQuery'

import { onEnterKeyPress } from 'utils/keyboard'
import { trackCtaClicked } from 'utils/tracking/analytics'
import { processUserCoursesData } from 'utils/userCourseDataUtils'

const SEE_ALL_URL = COURSES_MY_COURSES_PATH
export const CourseInProgress = () => {
  const isSmallerView = useMediaQuery('(max-width: 1099px)')
  const history = useHistory()
  const { data: userCoursesData, loading: userCoursesDataLoading } = useMyCoursesQuery()

  const user = userCoursesData?.currentUser
  const userCourses = user?.userCourses
  const userCohorts = user?.cohorts
  const userProgramsInProgress = user?.userProgramsProgressStarted
  const legacyMarketingCourses = userCoursesData?.legacyCourses
  const marketplaceMarketingCourses = userCoursesData?.marketplaceCourses
  const inProgressCourses = user?.inProgressCourses

  const {
    legacyCoursesByProgramSlug,
    marketplaceCoursesByCourseSanityId,
    sortedInProgressSortables
  } = processUserCoursesData(
    userCourses,
    userCohorts,
    userProgramsInProgress,
    inProgressCourses,
    legacyMarketingCourses,
    marketplaceMarketingCourses,
    user
  )

  const SEE_ALL_IN_PROGRESS_COURSES_TEXT = 'View all'
  const handleSeeAllClick = () => {
    trackCtaClicked({
      cta_location: 'premium_user_home',
      cta_type: 'button',
      text: SEE_ALL_IN_PROGRESS_COURSES_TEXT
    })
    history.push(SEE_ALL_URL)
  }

  return (
    <div className="@container/CoursesInProgress flex flex-col gap-4">
      <div className="flex flex-col xs:flex-row xs:items-center w-full align items-start text-xl font-semibold">
        <span>Continue your course</span>
        <div
          role="link"
          tabIndex={0}
          onKeyUp={onEnterKeyPress(handleSeeAllClick)}
          className="h-10 px-4 hidden xs:flex items-center text-rb-gray-300 font-normal text-sm cursor-pointer"
          onClick={handleSeeAllClick}
        >
          <>
            {SEE_ALL_IN_PROGRESS_COURSES_TEXT}
            {ChevronRightThinIcon}
          </>
        </div>
      </div>

      {userCoursesDataLoading && (
        <div className="grid w-full mb-4 grid-cols-[repeat(auto-fill,minmax(300px,1fr))] sm:mb-0 gap-4 @2xl/CoursesInProgress:grid-cols-2">
          <SkeletonMiniCard />
          <SkeletonMiniCard />
        </div>
      )}

      {!userCoursesDataLoading && (
        <div className="grid w-full mb-4 grid-cols-[repeat(auto-fill,minmax(300px,1fr))] sm:mb-0 gap-4 @2xl/CoursesInProgress:grid-cols-2">
          {sortedInProgressSortables.slice(0, 2).map((sortable) => {
            return (
              <div key={sortable.entity.id}>
                {renderSortable({
                  variant: isSmallerView ? CardVariants.Mini : CardVariants.BiggerMini,
                  isHomeFeedCard: true,
                  sortable,
                  legacyCoursesByProgramSlug,
                  marketplaceCoursesByCourseSanityId,
                  user,
                  additionalRelatedIdentifiers: {
                    section: 'in_progress_courses',
                    local_id:
                      sortable.entity.__typename === 'CclCourse'
                        ? sortable.entity.id
                        : undefined,
                    static_id:
                      sortable.entity.__typename === 'CclCourse'
                        ? sortable.entity.staticId
                        : undefined
                  }
                })}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default CourseInProgress
