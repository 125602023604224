import { Season } from 'gql'

export const seasonMock: Season = {
  __typename: 'Season',
  id: '37',
  name: 'Fall',
  year: '1900',
  openForEnrollment: false,
  hasStarted: false,
  nextSeasonName: 'Spring',
  prettyNameSeasonFirst: 'Fall 1900'
}

export const enrollableSeasonMock: Season = {
  ...seasonMock,
  name: 'Upcoming',
  year: '2100',
  openForEnrollment: true,
  hasStarted: true,
  prettyNameSeasonFirst: 'Upcoming 2100'
}
