import React from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'

import Dropdown from 'components/dropdowns/Dropdown'
import { CloseIcon } from 'components/icons'

import { MAX_WIDTH_TAILWIND_XS } from 'constants/breakpoints'

import { CurrentUserPartsFragment } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'
import useMediaQuery from 'hooks/useMediaQuery'

import notifyError from 'utils/errorNotifier'
import { trackNavigationClicked } from 'utils/tracking/analytics'

import BadgeableAvatar from './BadgeableAvatar'
import Button from './Button'

export enum UserType {
  FreeUser = 'free_user',
  Trialer = 'trialer',
  IndividualMember = 'individual_member',
  Creator = 'creator',
  TeamMember = 'team_member',
  PlanManager = 'plan_manager'
}

export type Action = {
  label: string
  userTypes: UserType[]
  onClick: () => void
}

interface AccountDropdownUIProps {
  avatarUrl: string
  fullName: string
  userType: UserType
  actions: Action[]
}

const DefaultMenu: React.FC<AccountDropdownUIProps> = ({
  avatarUrl,
  fullName,
  userType,
  actions
}) => {
  const getActionsForUserType = (userType: UserType): Action[] => {
    return actions.filter((action) => action.userTypes.includes(userType))
  }

  const triggerElement = (
    <BadgeableAvatar
      className="flex ml-2 shrink-0"
      avatarUrl={avatarUrl}
      width={40}
      height={40}
      fullName={fullName}
    />
  )

  return (
    <Dropdown
      triggerElement={triggerElement}
      positions={['bottom']}
      padding={10}
      align="end"
      dismissOnClick={true}
      containerClassName="shadow-2xl"
    >
      <div className="border border-rb-gray-100 bg-white px-1 py-2 w-60">
        {getActionsForUserType(userType).map((action, index) => (
          <button
            key={index}
            onClick={action.onClick}
            className="flex h-8 w-full items-center px-2 py-1 hover:bg-rb-gray-50 hover:rounded-md"
          >
            {action.label}
          </button>
        ))}
      </div>
    </Dropdown>
  )
}

const FullScreenMenu: React.FC<AccountDropdownUIProps & { onClose: () => void }> = ({
  avatarUrl,
  fullName,
  userType,
  onClose,
  actions
}) => {
  const getActionsForUserType = (userType: UserType): Action[] => {
    return actions.filter((action) => action.userTypes.includes(userType))
  }

  return (
    <div className="fixed flex flex-col inset-0 z-[1002] bg-white px-4">
      <div className="flex items-center p-2">
        <BadgeableAvatar
          className="ml-2 shrink-0"
          avatarUrl={avatarUrl}
          width={40}
          height={40}
          fullName={fullName}
        />
        <span className="overflow-x-hidden whitespace-nowrap pl-3 text-base font-semibold text-rb-black">
          {fullName}
        </span>
        <button onClick={onClose} className="ml-auto">
          <CloseIcon className="h-6 w-6" />
        </button>
      </div>
      <ul className="flex flex-col p-2 list-none">
        {getActionsForUserType(userType)
          .filter((action) => action.label !== 'Log out')
          .map((action, index) => (
            <li key={index} className="p-4 m-0 hover:bg-rb-gray-50 hover:rounded-md">
              <button
                onClick={() => {
                  action.onClick()
                  onClose()
                }}
                className="w-full text-left"
              >
                {action.label}
              </button>
            </li>
          ))}
      </ul>
      <Button
        variant="outline"
        className="w-full mt-auto mb-4 text-base"
        onClick={() => {
          window.location.href = '/logout'
        }}
      >
        Log out
      </Button>
    </div>
  )
}

export const AccountDropdownUI: React.FC<AccountDropdownUIProps> = (props) => {
  const isMobile = useMediaQuery(`(max-width: ${MAX_WIDTH_TAILWIND_XS})`)
  const [isOpen, setIsOpen] = React.useState(false)

  if (isMobile) {
    return (
      <>
        <button onClick={() => setIsOpen(true)}>
          <BadgeableAvatar
            className="flex ml-2 shrink-0"
            avatarUrl={props.avatarUrl}
            width={40}
            height={40}
            fullName={props.fullName}
          />
        </button>
        {isOpen && <FullScreenMenu {...props} onClose={() => setIsOpen(false)} />}
      </>
    )
  }

  return <DefaultMenu {...props} />
}

export const AccountDropdown: React.FC = () => {
  const { currentUser } = useCurrentUser()
  const navigate = useNavigate()

  if (!currentUser) {
    return null
  }

  const actions: (currentUser: CurrentUserPartsFragment) => Action[] = (currentUser) => {
    const profileSlug = currentUser.profile?.sanitySlug || ''
    const creatorIsOnTeamSub = currentUser.is.creator && currentUser.is.paidMember
    return [
      {
        label: 'My account',
        userTypes: [
          UserType.FreeUser,
          UserType.Trialer,
          UserType.IndividualMember,
          UserType.Creator,
          UserType.TeamMember,
          UserType.PlanManager
        ],
        onClick: () => {
          trackNavigationClicked({
            text: 'My account',
            destination: '/account',
            type: 'top nav link',
            location: 'topbar_navigation',
            logged_in: true,
            path: window.location.pathname
          })
          navigate('/account')
        }
      },
      {
        label: 'Start a free, 14-day trial',
        userTypes: [UserType.FreeUser],
        onClick: () => {
          trackNavigationClicked({
            text: 'Start a free, 14-day trial',
            destination: '/try-reforge',
            type: 'top nav link',
            location: 'topbar_navigation',
            logged_in: true,
            path: window.location.pathname
          })
          navigate('/try-reforge')
        }
      },
      {
        label: 'Join a team',
        userTypes: [UserType.FreeUser, UserType.Trialer].concat(
          creatorIsOnTeamSub ? [] : [UserType.Creator]
        ),
        onClick: () => {
          trackNavigationClicked({
            text: 'Join a team',
            destination: '/account',
            type: 'top nav link',
            location: 'topbar_navigation',
            logged_in: true,
            path: window.location.pathname
          })
          navigate('/account')
        }
      },
      {
        label: 'Become a creator',
        userTypes: [
          UserType.FreeUser,
          UserType.Trialer,
          UserType.IndividualMember,
          UserType.TeamMember,
          UserType.PlanManager
        ],
        onClick: () => {
          trackNavigationClicked({
            text: 'Become a creator',
            destination: '/creators',
            type: 'top nav link',
            location: 'topbar_navigation',
            logged_in: true,
            path: window.location.pathname
          })
          openInNewTab('/creators')
        }
      },
      {
        label: 'My profile',
        userTypes: [UserType.Creator],
        onClick: () => {
          if (!profileSlug) {
            notifyError('Profile slug is not defined for this user')
            return
          }
          trackNavigationClicked({
            text: 'My profile',
            destination: `/profiles/${profileSlug}`,
            type: 'top nav link',
            location: 'topbar_navigation',
            logged_in: true,
            path: window.location.pathname
          })
          navigate(`/profiles/${profileSlug}`)
        }
      },
      {
        label: 'Publish with Reforge',
        userTypes: [UserType.Creator],
        onClick: () => {
          trackNavigationClicked({
            text: 'Publish with Reforge',
            destination:
              'https://artifacts.typeform.com/share-my-work?typeform-source=www.reforge.com',
            type: 'top nav link',
            location: 'topbar_navigation',
            logged_in: true,
            path: window.location.pathname
          })
          openInNewTab(
            'https://artifacts.typeform.com/share-my-work?typeform-source=www.reforge.com'
          )
        }
      },
      {
        label: 'Manage team',
        userTypes: [UserType.PlanManager],
        onClick: () => {
          trackNavigationClicked({
            text: 'Manage team',
            destination: '/team_members',
            type: 'top nav link',
            location: 'topbar_navigation',
            logged_in: true,
            path: window.location.pathname
          })
          navigate('/team_members')
        }
      },
      {
        label: 'Log out',
        userTypes: [
          UserType.FreeUser,
          UserType.Trialer,
          UserType.IndividualMember,
          UserType.Creator,
          UserType.TeamMember,
          UserType.PlanManager
        ],
        onClick: () => {
          trackNavigationClicked({
            text: 'Log out',
            destination: '/logout',
            type: 'top nav link',
            location: 'topbar_navigation',
            logged_in: true,
            path: window.location.pathname
          })
          window.location.href = '/logout'
        }
      }
    ]
  }

  const userType = (() => {
    switch (true) {
      case currentUser.is.creator:
        return UserType.Creator
      case currentUser.is.trialing:
        return UserType.Trialer
      case currentUser.is.individualMember:
        return UserType.IndividualMember
      case currentUser?.is?.planManager:
      case currentUser.is?.subscriptionOwner:
        return UserType.PlanManager
      case currentUser.is.paidMember:
        return UserType.TeamMember
      case currentUser.is.freeUser:
        return UserType.FreeUser
      default:
        return UserType.FreeUser
    }
  })()

  return (
    <AccountDropdownUI
      avatarUrl={currentUser.profile?.avatarUrl || ''}
      fullName={currentUser.fullName || ''}
      userType={userType}
      actions={actions(currentUser)}
    />
  )
}

const openInNewTab = (path: string) => {
  window.open(path, '_blank', 'noopener,noreferrer')
}

export default AccountDropdown
