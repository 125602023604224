import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import { Loading } from 'components'

import { useExploreTopicsQuery } from 'gql'

import { useCurrentUser } from 'hooks/useCurrentUser'

import { cn } from 'utils/tailwind'
import { trackNavigationClicked } from 'utils/tracking/analytics'

import { TOPICS } from '../consts'

type Tracking = {
  locationType: string
  sectionIndex: number
}

function VerticalTopicCard({
  title,
  icon,
  slug,
  listIndex,
  id,
  tracking
}: {
  title: string
  icon: React.ReactNode
  slug: string
  listIndex: number
  id: string
  tracking: Tracking
}) {
  const location = useLocation()
  const { isLoggedIn } = useCurrentUser()

  const destination = `/explore/${slug}`

  return (
    <Link
      className={cn(
        'flex flex-col justify-between items-center border border-rb-gray-100 hover:border-rb-gray-200 p-4 md:p-8 rounded shadow hover:no-underline text-black hover:text-black'
      )}
      to={destination}
      onClick={() => {
        trackNavigationClicked({
          type: 'topic_card',
          text: title.toLowerCase(),
          location: location.pathname,
          location_type: tracking.locationType,
          logged_in: isLoggedIn,
          destination,
          related_identifiers: {
            destination_ccl_filter_tag_id: id,
            destination_kind: 'topic'
          },
          section_index: tracking.sectionIndex,
          section_impression_index: listIndex
        })
      }}
    >
      <div className="">
        <div className="flex justify-center">{icon}</div>
        <div className="text-sm md:text-xl font-semibold text-center">{title}</div>
      </div>
    </Link>
  )
}

export default function MoreTopicsToExplore({ tracking }: { tracking: Tracking }) {
  const { data: exploreTopicsData, loading } = useExploreTopicsQuery()

  if (loading) {
    return <Loading />
  }

  return (
    <div>
      <h2 className="text-lg">More Topics To Explore</h2>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
        {TOPICS.map((topic, idx) => {
          const exploreTopic = exploreTopicsData?.exploreTopics.find(
            (exploreTopic: any) => {
              return exploreTopic.slug === topic.slug
            }
          )

          if (!exploreTopic) {
            return null
          }

          return (
            <VerticalTopicCard
              key={`topic-${topic.title}`}
              title={topic.title}
              icon={topic.icon}
              slug={exploreTopic.slug}
              id={exploreTopic.id}
              listIndex={idx}
              tracking={tracking}
            />
          )
        })}
      </div>
    </div>
  )
}
