import { MutationHookOptions, QueryHookOptions } from '@apollo/client'
import { DocumentNode } from 'graphql'

import * as gql from 'gql'

export type ApolloMock<QueryData, VariablesData> = {
  request: {
    query: DocumentNode
    variables: VariablesData | undefined
    operationName?: string
  }
  result: {
    data: Omit<QueryData, '__typename'>
  }
}
type InferFromFunctionParam<T> = T extends (a: infer F) => void ? F : never
type InferDataFromHookOptions<T> = T extends
  | QueryHookOptions<infer QueryData>
  | MutationHookOptions<infer QueryData>
  ? QueryData
  : never
type InferVariablesFromHookOptions<T> = T extends
  | QueryHookOptions<any, infer VariablesData>
  | MutationHookOptions<any, infer VariablesData>
  ? VariablesData
  : never
type FilterHooks<T> = {
  [K in keyof T as K extends `use${string}` ? K : never]-?: T[K]
}
type ValueOf<T> = T[keyof T]
type Hooks = ValueOf<FilterHooks<typeof gql>>
type InferData<Query> = Omit<
  InferDataFromHookOptions<InferFromFunctionParam<Query>>,
  '__typename'
>
type InferVariables<Query> = InferVariablesFromHookOptions<InferFromFunctionParam<Query>>

// Used to ensure that createApolloMock is only called with a generated query/mutation
const hooks = Object.keys(gql).filter((name) => name.startsWith('use'))

const operationNameMap: Record<string, number> = {}

const getOperationName = (query: DocumentNode) => {
  const operationDefinition = query.definitions.find(function (definition) {
    return definition.kind === 'OperationDefinition'
  })
  if (operationDefinition && 'name' in operationDefinition && operationDefinition.name) {
    return operationDefinition.name.value
  }

  return 'UnnamedOperation'
}

export const createApolloMockObject = <QueryData, VariablesData>(
  operation: DocumentNode,
  data: Omit<QueryData, '__typename'> & { __typename?: 'Query' },
  variables?: VariablesData
): ApolloMock<QueryData, VariablesData> => {
  const operationName = getOperationName(operation)
  operationNameMap[operationName] = (operationNameMap[operationName] || 0) + 1

  return {
    request: {
      query: operation,
      variables,
      operationName: `${operationName}_${operationNameMap[operationName]}`
    },
    result: { data }
  }
}

/**
 * Type safe wrapper to create Apollo mocks
 * @param queryHook hook imported from 'gql'
 * @param data typesafe data to be mocked
 * @param variables typesafe variables to be mocked
 * @returns ApolloMock
 */
export function createApolloMock<Query extends Hooks>(
  queryHook: Query,
  data: InferData<Query>,
  variables?: InferVariables<Query>
): ApolloMock<InferData<Query>, InferVariables<Query>> {
  const hookName = queryHook.name

  if (hooks.indexOf(hookName) === -1) {
    // print the stack trace
    console.log(new Error().stack)
    throw new Error(
      `Could not find hook ${hookName} in gql, called with\n data: ${JSON.stringify(data, null, 2)}\n variables: ${JSON.stringify(variables, null, 2)}\n. You must use a hook imported from 'gql'.`
    )
  }

  // Extracting DocumentNode name from hook name
  // e.g. useUserSubscriptionModalQuery -> UserSubscriptionModalDocument
  // e.g. useCurrentUserLazyQuery -> CurrentUserDocument
  // e.g. useActivateShareableLinkMutation -> ActivateShareableLinkDocument
  let documentName = hookName.slice(3) // remove 'use'
  documentName = documentName.endsWith('LazyQuery')
    ? documentName.slice(0, -9)
    : documentName
  documentName = documentName.endsWith('Query') ? documentName.slice(0, -5) : documentName
  documentName = documentName.endsWith('Mutation')
    ? documentName.slice(0, -8)
    : documentName
  documentName = `${documentName}Document`

  return createApolloMockObject(
    gql[documentName as keyof typeof gql] as DocumentNode,
    data,
    variables
  )
}
