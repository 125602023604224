import { CohortPartFragment, UserCohortPartFragment, UserCohortsPartsFragment } from 'gql'

import { cmsProgramMock } from './cmsProgramMocks'
import { seasonMock } from './seasonMocks'

export const cohortMock: CohortPartFragment = {
  __typename: 'Cohort',
  id: '22',
  slug: 'cohort-mock',
  eventCount: 0,
  weekCount: 0,
  isOngoing: false,
  startsAt: null,
  endsAt: null,
  postCohort: false,
  coreWeeksHaveEnded: false,
  postCoreWeeksEnrollmentIsOpen: false,
  postKickoff: false,
  season: seasonMock,
  cmsProgram: cmsProgramMock
}

export const userCohortMock: UserCohortPartFragment = {
  __typename: 'UserCohort',
  id: '1',
  cohort: { ...cohortMock }
}

export const userCohortsMock: UserCohortsPartsFragment = {
  __typename: 'User',
  cohorts: {
    __typename: 'UserCohorts' as const,
    current: [
      {
        __typename: 'UserCohort' as const,
        cohort: {
          __typename: 'Cohort' as const,
          id: '335',
          postCohort: true,
          hasEnded: true,
          postKickoff: true
        }
      }
    ],
    latestCurrent: {
      id: '44296',
      canBeUpdated: false,
      hasClickedMeetCohortParticipantsCta: ['170'],
      hasClickedShareSnapshotCta: false,
      hasSignedStatementOfCommitment: true,
      showEventRsvpModal: false,
      hasPostedWelcome: true,
      timeGroupingSelected: 'Thursday0830Thursday1000',
      snapshot: {
        id: '28718',
        token: 'TJuzZaC67HJ0baz5DL61CQ',
        released: true,
        __typename: 'Snapshot' as const
      },
      cohort: {
        id: '170',
        postCohort: true,
        hasEnded: true,
        slackChannelId: 'C03R0BDHWKE',
        weekCount: 4,
        cmsProgram: {
          id: '126',
          code: 'ts',
          name: 'Technical Strategy',
          __typename: 'CmsProgram' as const,
          heroImageUrl:
            'https://reforge-uploads-dev.s3.amazonaws.com/uploads/c1r13tg4nmei1s4ytz8eo2980410',
          programImage:
            'https://reforge-uploads-dev.s3.amazonaws.com/uploads/zf5bcy3jax40xo8hav9q7027bw4g',
          synopsis:
            'Learn how to go beyond executing strategy and gain the tools to shape strategy, educate peers on what is possible, and guide the product roadmap to deliver significant business impact.',
          lessonCount: 33
        },
        batchRsvpTimeData: [],
        season: {
          id: '39',
          name: 'fall',
          year: '2022',
          npsSurveyLink:
            'https://survey.alchemer.com/s3/7077851/2022-Fall-End-of-Cohort-Survey',
          __typename: 'Season' as const
        },
        thisWeek: null,
        nextWeek: null,
        scheduledWeeks: [
          {
            id: '685',
            startsAt: '2022-09-30T02:00:00+00:00',
            __typename: 'ScheduledWeek' as const,
            bonus: false,
            postCohort: false,
            endsAt: '2022-10-07T02:00:00+00:00'
          },
          {
            id: '686',
            startsAt: '2022-10-07T02:00:00+00:00',
            __typename: 'ScheduledWeek' as const,
            bonus: false,
            postCohort: false,
            endsAt: '2022-10-14T02:00:00+00:00'
          },
          {
            id: '687',
            startsAt: '2022-10-14T02:00:00+00:00',
            __typename: 'ScheduledWeek' as const,
            bonus: false,
            postCohort: false,
            endsAt: '2022-10-21T02:00:00+00:00'
          },
          {
            id: '688',
            startsAt: '2022-10-21T02:00:00+00:00',
            __typename: 'ScheduledWeek' as const,
            bonus: false,
            postCohort: false,
            endsAt: '2022-10-28T02:00:00+00:00'
          },
          {
            id: '689',
            startsAt: '2022-10-28T02:00:00+00:00',
            __typename: 'ScheduledWeek' as const,
            bonus: false,
            postCohort: false,
            endsAt: '2022-11-04T02:00:00+00:00'
          },
          {
            id: '690',
            startsAt: '2022-11-04T02:00:00+00:00',
            __typename: 'ScheduledWeek' as const,
            bonus: false,
            postCohort: true,
            endsAt: '2022-11-11T02:00:00+00:00'
          }
        ],
        __typename: 'Cohort' as const,
        postKickoff: true,
        slug: '2022-fall-technical-strategy-eg',
        kickoffEvents: [
          {
            id: '1739',
            startsAtUtc: '2022-10-06T15:30:00+00:00',
            __typename: 'Event' as const
          }
        ],
        events: [
          {
            id: '1739',
            startsAtUtc: '2022-10-06T15:30:00+00:00',
            __typename: 'Event' as const
          },
          {
            id: '1740',
            startsAtUtc: '2022-10-13T15:30:00+00:00',
            __typename: 'Event' as const
          },
          {
            id: '1741',
            startsAtUtc: '2022-10-20T15:30:00+00:00',
            __typename: 'Event' as const
          },
          {
            id: '1742',
            startsAtUtc: '2022-10-27T15:30:00+00:00',
            __typename: 'Event' as const
          },
          {
            id: '1743',
            startsAtUtc: '2022-11-03T15:30:00+00:00',
            __typename: 'Event' as const
          }
        ],
        hosts: [
          {
            id: '32',
            name: 'Louis Bennett',
            thumbnailUrl:
              'https://reforge-uploads-dev.s3.amazonaws.com/uploads/nyjbayw3gvza7wtseairmc15czwy',
            previousCompanies: 'VSCO, Intercom, Trulia',
            title: 'Executive in Residence @ Reforge',
            __typename: 'Host' as const
          }
        ],
        postCoreWeeksEnrollmentIsOpen: true
      },
      __typename: 'UserCohort' as const
    }
  }
}
