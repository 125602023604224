import { PlanForSale, PlanName, usePlansForSaleQuery } from 'gql'

import { createApolloMock } from 'utils/testing'

export const plansForSale: PlanForSale[] = [
  {
    name: PlanName.INDIVIDUAL,
    pricePerYear: 1000,
    pricePerPerson: 1000,
    maxSeatCount: 1,
    stripeId: 'plan_123',
    __typename: 'PlanForSale'
  },
  {
    name: PlanName.STARTER,
    pricePerYear: 5995,
    pricePerPerson: 5995,
    maxSeatCount: 10,
    stripeId: 'plan_456',
    __typename: 'PlanForSale'
  },
  {
    name: PlanName.SCALE,
    pricePerYear: 14995,
    pricePerPerson: 14995,
    maxSeatCount: 30,
    stripeId: 'plan_789',
    __typename: 'PlanForSale'
  }
]

export const PLANS_FOR_SALE_MOCK = {
  plansForSale
}

export const plansForSaleMock = createApolloMock(usePlansForSaleQuery, {
  ...PLANS_FOR_SALE_MOCK
})
